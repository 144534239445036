.list-items .title {
  display: inline-block; }

.list-items .add, .list-items .delete {
  float: right; }

.list-items .add {
  margin-top: 20px; }

.list-items .delete {
  margin-bottom: 5px; }

.list-items .items {
  list-style: none; }

.list-items .row:first {
  border-bottom: 1px solid #eaeaea; }

.list-items .page-title {
  color: #3c3c3c;
  text-decoration: none;
  line-height: 30px; }

.list-items .col-sm-8 {
  border-bottom: none !important; }

.list-items .head.col-sm-8 {
  border-bottom: 1px solid #eaeaea !important; }
