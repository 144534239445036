// Colors
$red: #FF0000;
$green: #00505A;
$lightGreen: #CCDCDE;
$white: #fff;
$lightGrey: #eaeaea;
$darkGrey: #3c3c3c;

// Typography
@mixin Rubik {
  font-family: 'Rubik', sans-serif !important; }

body, h1, h2, h3, h4 {
  @include Rubik; }

header {
  &, .logo {
    background-color: $red !important; }

  .logo a, ul li a {
    font-weight: normal;
    color: $white !important;
    &.active, &:hover, &:focus {
      border-bottom-color: $white; } } }

.App header .hamburger {
  .icon {
    &, &:after, &:before {
      background-color: $white; } } }

.menu-opened .App header .hamburger .icon {
  background-color: transparent; }

.App header .main-menu {
  background-color: $red;

  a {
    display: inline-block; } }

.medium-editor-element {
  outline: none; }

.texts-section {
  padding-bottom: 40px;
  h2 {
    margin-bottom: 20px; }
  .label {
    font-weight: bold; } }
