.map-container {
  position: absolute;
  top: 0;
  width: 100%;
  height: 40vh;
  transition: height 0.3s;

  &.crosshair-map {
    &:after {
      content: " ";
      display: block;
      width: 10px;
      height: 10px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background-color: red;
      z-index: 9999;
      border-radius: 100%; } }

  .overlay-content {
    max-width: 100px;
    img {
      width: 100%; } } }

.marker {
  position: absolute;
  width: 20px;
  height: 20px;
  padding: 8px;
  transform: translate(-50%, -50%);
  transition: 0.3s all;

  &:after {
    content: " ";
    display: block;
    height: 100%;
    background-color: red;
    border-radius: 100%; }

  &.highlighted {
    padding: 7px; }

  &.active {
    z-index: 2;
    padding: 5px; } }

@media (min-width: 768px) {
  .map-container {
    height: 100%;

    .marker {
      width: 8px;
      height: 8px;
      padding: 2px;

      &:hover {
        cursor: pointer; }

      &.highlighted, &.active {
        padding: 0px; }

      &.active {
        width: 10px;
        height: 10px; } } }

  .public-layout {
    .map-container.crosshair-map {
      &:after {
        margin-left: 300px; } } } }
