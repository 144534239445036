.category-items-filter .add {
  margin-top: 0; }

.category-items-filter .add-link {
  margin: 0;
  float: right; }

.category-items-filter a, .category-items-filter p {
  display: inline-block;
  margin-right: 10px;
  margin-bottom: 0;
  line-height: 30px;
  color: #3c3c3c; }

.category-items-filter .table {
  margin-top: 20px; }

@media (min-width: 768px) {
  .category-items-filter .add-link {
    margin-top: 20px; }
  .category-items-filter .category-select {
    margin-top: 20px; } }
