@media (min-width: 768px) {
  header .hamburger {
    display: none; }
  header li {
    display: inline-block;
    padding: 15px; }
    header li a {
      display: inline-block;
      padding: 0;
      line-height: 21px; }
      header li a:hover, header li a:active, header li a:focus {
        border-bottom: 2px solid #3c3c3c; } }
