.rdw-editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px; }
  .rdw-editor-wrapper .public-DraftStyleDefault-block {
    margin: 0; }
  .rdw-editor-wrapper .rdw-editor-main {
    padding: 20px; }
    .rdw-editor-wrapper .rdw-editor-main img {
      max-width: 100px; }
  .rdw-editor-wrapper .rdw-dropdown-selectedtext {
    color: #000; }
  .rdw-editor-wrapper h1 {
    margin-top: 0; }
  .rdw-editor-wrapper .rdw-image-modal-upload-option-label {
    font-size: 14px;
    text-align: center;
    padding: 0 20px; }

.text-editor label {
  margin-bottom: 0; }

.text-editor .panel-title {
  font-size: 14px; }
