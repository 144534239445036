.admin-item {
  padding-top: 20px; }
  .admin-item .underlined {
    border-bottom: 1px solid #eaeaea;
    margin-bottom: 20px; }
    .admin-item .underlined:last-child {
      border-bottom: none; }
    .admin-item .underlined:first-child .btn {
      float: right; }
    .admin-item .underlined:first-child .btn-link {
      color: #d9534f;
      font-weight: bold;
      margin-right: 10px; }
    .admin-item .underlined h3 {
      margin-top: 0; }

@media (min-width: 768px) {
  .admin-item .underlined {
    border-bottom: none; }
    .admin-item .underlined .col-sm-8:last-child {
      border-bottom: 1px solid #eaeaea; } }
