header {
  background-color: #eaeaea; }
  header .logo {
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #eaeaea; }
    header .logo a, header .logo a:hover, header .logo a:active, header .logo a:focus {
      color: #3c3c3c;
      text-decoration: none; }
  header .logo, header .hamburger {
    position: relative;
    z-index: 3; }
  header ul {
    list-style: none;
    text-align: right;
    margin-bottom: 0; }
  header a {
    border-bottom: 2px solid transparent; }
    header a, header a:hover, header a:active, header a:focus {
      color: #3c3c3c;
      text-decoration: none; }
    header a.active {
      border-bottom-color: #3c3c3c; }

@media (max-width: 768px) {
  header .hamburger {
    position: absolute;
    top: 0;
    right: 0;
    padding: 21px 20px; }
    header .hamburger .icon {
      position: relative;
      margin-top: 5px;
      margin-bottom: 5px;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none; }
      header .hamburger .icon, header .hamburger .icon::before, header .hamburger .icon::after {
        display: block;
        width: 20px;
        height: 2px;
        background-color: #3c3c3c;
        outline: 1px solid transparent;
        -webkit-transition-property: background-color, -webkit-transform;
        -moz-transition-property: background-color, -moz-transform;
        -o-transition-property: background-color, -o-transform;
        transition-property: background-color, transform;
        -webkit-transition-duration: 0.3s;
        -moz-transition-duration: 0.3s;
        -o-transition-duration: 0.3s;
        transition-duration: 0.3s; }
      header .hamburger .icon::before, header .hamburger .icon::after {
        position: absolute;
        content: ""; }
      header .hamburger .icon::before {
        top: -5px; }
      header .hamburger .icon::after {
        top: 5px; }
  header .main-menu {
    position: absolute;
    width: 100%;
    top: 53px;
    left: 0;
    z-index: 2;
    transform: translate(0, -180%);
    transition: transform 0.3s ease-in;
    clear: both;
    padding: 1px 20px 20px 20px;
    background-color: #eaeaea; }
    header .main-menu a {
      display: block;
      font-size: 16px;
      line-height: 32px;
      padding-bottom: 2px;
      transition: all 0.2s;
      border-bottom: 2px solid transparent; }
      header .main-menu a, header .main-menu a:hover, header .main-menu a:active, header .main-menu a:focus {
        color: #3c3c3c;
        text-decoration: none; }
  header .content-cover {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transform: translate3d(0, -100vh, 0);
    transition: transform 0s linear 0.6s, opacity 0.6s ease-in-out; } }

.menu-opened .main-menu {
  transform: translate(0, 0); }

.menu-opened .hamburger .icon {
  background-color: transparent; }

.menu-opened .hamburger .icon::before {
  -webkit-transform: translateY(5px) rotate(45deg);
  -moz-transform: translateY(5px) rotate(45deg);
  -ms-transform: translateY(5px) rotate(45deg);
  -o-transform: translateY(5px) rotate(45deg);
  transform: translateY(5px) rotate(45deg); }

.menu-opened .hamburger .icon::after {
  -webkit-transform: translateY(-5px) rotate(-45deg);
  -moz-transform: translateY(-5px) rotate(-45deg);
  -ms-transform: translateY(-5px) rotate(-45deg);
  -o-transform: translateY(-5px) rotate(-45deg);
  transform: translateY(-5px) rotate(-45deg); }

.menu-opened .content-cover {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform 0s linear 0s, opacity 0.6s ease-in-out; }
