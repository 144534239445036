.add {
  margin-top: 20px;
  float: right; }

.sortable-list {
  list-style: none;
  padding: 0;
  border-bottom: 1px solid #eaeaea; }
  .sortable-list .list-item {
    position: relative;
    border-bottom: 1px solid #eaeaea; }
    .sortable-list .list-item:first-child {
      border-top: none; }
    .sortable-list .list-item:last-child {
      border-bottom: none; }
    .sortable-list .list-item .title {
      display: block;
      padding: 10px 0 10px 50px; }
      .sortable-list .list-item .title, .sortable-list .list-item .title:hover {
        color: #3c3c3c; }
      .sortable-list .list-item .title:focus, .sortable-list .list-item .title:active {
        text-decoration: none;
        color: #3c3c3c; }
  .sortable-list .handle {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
    background: url("./move-icon.svg") no-repeat 50% 50%; }
  .sortable-list .delete {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0, -50%); }
