.locations-admin {
  padding-bottom: 100px;

  h3 {
    display: inline-block; }

  .add-location {
    float: right; }

  .delete {
    position: absolute;
    top: -5px;
    right: 5px;
    color: red;
    font-weight: bold;
    z-index: 2; }

  .map-container {
    position: static;
    height: 600px; } }
