.bknd-form .error-container {
  height: 27px;
  padding-top: 1px; }

.bknd-form .error-message {
  font-size: 12px;
  margin: 4px 0 8px;
  color: red; }

.bknd-form .el-textarea.has-error textarea,
.bknd-form .el-input.has-error input {
  border-color: red; }
