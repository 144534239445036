.uploader-wrapper {
  position: relative;
  min-height: 270px; }

.images-headline {
  display: inline-block;
  margin-top: 0; }

.mobile-upload {
  position: relative;
  overflow: hidden;
  float: right; }
  .mobile-upload form {
    position: absolute;
    top: 0;
    right: 0;
    min-width: 100%;
    min-height: 100%;
    font-size: 100px;
    text-align: right;
    opacity: 0;
    outline: none;
    background: white;
    cursor: inherit;
    display: block; }

.images-list {
  background-color: #fff;
  padding: 0;
  list-style: none; }
  .images-list li {
    position: relative;
    height: 100px;
    border-bottom: 1px solid #eaeaea;
    padding: 10px 0 10px 50px; }
    .images-list li:first-child {
      border-top: 1px solid #eaeaea; }
    .images-list li .handle {
      display: block;
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(0, -50%);
      background: url("./move-icon.svg") no-repeat 50% 50%; }
    .images-list li img {
      height: 100%; }
    .images-list li .delete {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(0, -50%); }

.desktop-uploader {
  position: absolute;
  top: 36px;
  left: 0;
  width: 100%;
  height: calc(100% - 56px);
  z-index: -1;
  display: none;
  background: #fff url("plus.svg") no-repeat 50% 50%; }
  .desktop-uploader .dz-success, .desktop-uploader .dz-message {
    display: none !important; }

.draggedover .desktop-uploader {
  z-index: 1;
  display: block; }

.error {
  color: #d9534f;
  margin-left: 20px; }

@media (max-width: 768px) {
  .desktop-uploader {
    display: none; } }
